import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { MainHero } from '../components/Hero'
import { ContentHeader, Section } from '../components/Common'
import { useCharities } from '../GraphQL/Queries'
import { ImageBlockSection } from '../components/ImageBlock'
import { CharitiesForm } from '../components/FormSections'

const Charities = () => {
    const data = useCharities()
    const { acf_charitiesContent, acf_hero, acf_largeImageBlock } = data

    const hero = {
        title: acf_hero.bbTitle,
        description: acf_hero.bbDescription,
        image: acf_hero.heroImage.sourceUrlSharp.childImageSharp.fluid,
    }

    const charitiesContent = {
        title: acf_charitiesContent.mainTitle,
        description: acf_charitiesContent.mainDescription,
        form: {
            title: acf_charitiesContent.form.charitiesFormTitle,
            description: acf_charitiesContent.form.charitiesFormDescription,
            image:
                acf_charitiesContent.form.charitiesFormImage.sourceUrlSharp
                    .childImageSharp.fluid,
            alt: acf_charitiesContent.form.charitiesFormImage.altText,
        },
    }

    const imageBlock = {
        isSlider: acf_largeImageBlock.isslider,
        blueBoxTitle: acf_largeImageBlock.boxtitle,
        title: acf_largeImageBlock.title,
        description: acf_largeImageBlock.description,
        image: acf_largeImageBlock.image.sourceUrlSharp.childImageSharp.fluid,
        alt: acf_largeImageBlock.image.altText,
        posterimage: acf_largeImageBlock.posterimage,
        sliderContent: acf_largeImageBlock.slider,
    }

    return (
        <Layout pageClass={'charities'}>
            <SEO title="Charities" />
            <MainHero
                title={hero.title}
                description={hero.description}
                image={hero.image}
            />
            <ContentHeader
                title={charitiesContent.title}
                description={charitiesContent.description}
            />
            <ImageBlockSection
                slider={imageBlock.isSlider}
                sliderContent={imageBlock.sliderContent}
            />
            <Section>
                <CharitiesForm
                    image={charitiesContent.form.image}
                    alt={charitiesContent.form.alt}
                    title={charitiesContent.form.title}
                    description={charitiesContent.form.description}
                />
            </Section>
        </Layout>
    )
}

export default Charities
